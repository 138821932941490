import React, { Component } from 'react';
import axios from 'axios';
import QuizeComponent from './QuizeComponent';
 class Practice extends Component{
    constructor(){
    super();
    this.state = {
        text:"",
        record:0,
        master:9,
        data:[],
        hint:0,
        dataResponse:false,
        limitlogResponse:-1,
        instruction:true,
        noskipresetetc:true,
        audioFile:'',
        quizeMode:false,
        playedWords:[]
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.toggleHint = this.toggleHint.bind(this);
    this.updateMaster = this.updateMaster.bind(this);
    this.repractice =this.repractice.bind(this);
    this.addPracticeLog = this.addPracticeLog.bind(this);
    this.fetchPracticeLog = this.fetchPracticeLog.bind(this);

    this.updateCurrentValue = this.updateCurrentValue.bind(this);
    this.toggleInstruction = this.toggleInstruction.bind(this);
    this.reset = this.reset.bind(this);
    this.updatequizeMode = this.updatequizeMode.bind(this);

    }
    
    componentDidMount(){

        
        axios.post( localStorage.getItem('base_url')+'getLastviewed',{catagory:this.props.match.params.id,user:localStorage.getItem('user_id')} ).then(res =>{
           if(res.data != ""){
            console.log(res.data , "data")
            if(res.data.lastviewed != "" && res.data.master != "" )
                {
                    this.setState({ record:parseInt(res.data.lastviewed),master:parseInt(res.data.master) });
                    this.fetchWord(parseInt(res.data.lastviewed));
                }
           }
           else{
            this.fetchWord(parseInt(0));
           }
           this.setState({ playedWords:[] })
                
        })
        this.fetchPracticeLog();
        if(this.props.match.params.id == 2 || this.props.match.params.id == 6){
            this.setState({noskipresetetc:false})
        }
       
    }

    previous(){
        let record=this.state.record;
        if( this.state.record > 0 )
            record -= 1
        this.setState({ record:record});
        this.fetchWord(record); 
    }
    next(){
        
        let record=this.state.record;
        // if(this.state.record < this.state.master )
        //     record += 1
        // if((parseInt(this.state.record)+1)%10 == 0){
        //     this.addPracticeLog(this.state.record)
        // }

        // if(parseInt(this.state.record) == parseInt(this.state.master)){
        //     record = 0;
        // }
        record += 1
        console.log(this.state.record,this.state.master);
        this.setState({ record:record});
        this.fetchWord(record);
        this.updateCurrentValue(record);
        
    }

    updateCurrentValue(record){
        const user = localStorage.getItem('user_id');
        axios.post(localStorage.getItem('base_url')+'updatelastviewd',{record:record,master:this.state.master,catagory:this.props.match.params.id,user:localStorage.getItem('user_id')}).then(res=> {

        })
    }
    addPracticeLog(limitlog){
        axios.post(localStorage.getItem('base_url')+'practicelog',{ user:localStorage.getItem('user_id'),limitlog:limitlog });
    }
    fetchPracticeLog(){
        axios.get(localStorage.getItem('base_url')+'practicelog/'+localStorage.getItem('user_id')+'').then(res => {
            this.setState({ limitlogResponse:res.data })
        });
    }
    fetchWord(limit){
        axios.post(localStorage.getItem('base_url')+'wordbylimit',{limit:limit,master:this.state.master,catagory:this.props.match.params.id,user:localStorage.getItem('user_id') }).then(res =>{
            if(res.data[1] < 10)
                 this.setState({ limitlogResponse:-1,master : res.data[1] }); 
            if(res.data[0].length !== 0){
                this.setState({ 
                    data : res.data[0],
                    dataResponse:true,
                    hint:0
                 })

                //  const playedWords = this.state.playedWords.find((v) => v.id == e.target.id )
                //  const playedWordsIndex = this.state.playedWords.indexOf(playedWords);
                //  if(playedWordsIndex !== -1)
                //  this.state.playedWords.splice(playedWordsIndex,1);

                 
            }
            else{
                this.setState({ 
                    data :[],
                    dataResponse:false
                 })
            }

        })


       console.log(this.state.playedWords);
                

    }


    toggleHint(){
        if(this.state.hint == 0)
            this.setState({ hint:1 });
        else if(this.state.hint == 1 )
            this.setState( { hint:0} );
        // else if(this.state.hint == 2 )
        //     this.setState({ hint:0});
    }
    toggleInstruction(){
        if(this.state.instruction == false)
            this.setState({ instruction:true });
        else if(this.state.instruction == true )
            this.setState( { instruction:false} );
    }
    updateMaster(){
       let master = this.state.master
       master +=10
       this.setState({ master:master })
       this.fetchWord(this.state.record);
    }
    repractice(){
        const record = 0;
        this.setState({ record:record })
        this.fetchWord(record);
    }
    reset(){
        axios.post(localStorage.getItem('base_url')+'resetlastviewedvalue',{ user:localStorage.getItem('user_id'),catagory:this.props.match.params.id }).then(res =>{
            this.fetchWord(0);
            this.setState({ record:0,master:9 });
        });
        this.setState({playedWords:[]});
    }
    updatequizeMode(){
        this.componentDidMount.bind(this);
        let quizeMode = !this.state.quizeMode;
        this.setState({quizeMode:quizeMode});

    }
    render(){
        let masterButton = '';
        let quizButton ='';
        if(this.props.match.params.id == 3)
            quizButton = <button onClick={ this.updatequizeMode }  className="btn btn-info btn-block">tomar cuestionario</button>
        let response =<div className="wordText">No More Words </div>;
        let repracticeButton='';
        let skipButton ='';
        let reset ='';
        let instruction =<span><h3>¿Cómo funciona el programa?</h3>;
       
        <h4 className='instructionText'>
            <p>1.El programa entrega palabras y números en incrementos de 10.
            La palabra / número en español siempre se presenta primero.
            Haga click en el cuadro azulpara para ver la palabra en inglés junto con las sugerencias o pista de pronunciación.
            Use las sugerencias o pista de pronunciación para ayudar a pronunciar las palabras en inglés.
            </p><p>2.Continúe haciendo click en el botón "repetir" hasta que domine y aprenda las 10 primeras palabrasAl dominar la 10 primeras palabras hacer click en el botón dominado y automáticamente El programa entregará 10 palabras nuevas además de las 10 palabras aprendidas previamente. Continúe repasando y haga click en dominado la momento de aprender las palabras dadas según corresponda.
            </p><p>3.Después de alcanzar las 50 palabras, el programa le permite descartar las palabras más antiguas y comenzar el proceso nuevamente en incrementos de 50 hasta que haya dominado las 1000 palabras más importantes en inglés.
            </p><p>4.Haga clic en el botón de reinicio para iniciar el programa nuevamente desde el principio.
            </p><p>Recuerda La clave del aprendizaje es la repetición.</p></h4><p><button  className="btn btn-info float-right Esconder" onClick={this.toggleInstruction} >Esconder</button></p></span> 
        if(this.state.instruction == false){
            instruction = <button onClick={this.toggleInstruction} className="btn btn-info">Mostrar Instrucciones</button>
        }
        repracticeButton =<button onClick={this.repractice} className="btn btn-info btn-block">Continuar practicando</button>;
        if(this.state.noskipresetetc){
            reset = <button onClick={ this.reset } className="btn btn-info btn-block text-primary">Restablecer programa completamente</button>
        }
        

        let ComponentToShow = <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
       Current Count is = {parseInt(this.state.record)+1}
            <div className="box" onClick={this.toggleHint}>
                
                {
                    this.state.data.map((record,key)=>{
                        const quizeItem = this.state.playedWords.find((v) => v.id == record.id )
                        const quizeIndex = this.state.playedWords.indexOf(quizeItem);
                        if(quizeIndex !== -1)
                            this.state.playedWords.splice(quizeIndex,1);

                        this.state.playedWords.push({ id:record.id,english:record.english,spanish:record.spanish,option1:record.option1,option2:record.option2,option3:record.option3,option4:record.option4 });
                        if(this.state.dataResponse == true){
                            if(this.state.hint == 0){
                                if(this.props.match.params.id == '7')
                                    response = <img src={'https://gorilaingles.com/wordapis/public/assets/'+record.file } />
                                else
                                    response = <div className="wordText">{ record.spanish } <br />  </div>
                            }
                            if(this.state.hint == 1){
                                response = <div className="wordText"><p>English : { record.english } </p> <p> Sugerencia de pronunciación: { record.hint } </p><p>{record.file != 'empty' ? <audio controls>
                                <source src={'https://gorilaingles.com/wordapis/public/assets/'+record.file } type="audio/mpeg" />
                            Your browser does not support the audio element. </audio> : ''}</p></div>
                            }
                            // if(this.state.hint == 2)
                            // response = <span className="wordText">{ record.spanish }</span>
                        }
                    return(
                            <div key={key} className="boxChild">{ response }</div>
                        )
                    })
                
                }
            </div>
           <div className="row">
                <div className="col-md-3">
                    <button type="button" className="btn btn-primary" onClick={ this.previous }>Palabra anterior</button>
                </div>
                <div className="col-md-6">
                   { repracticeButton }
                </div>
                {/* <div className="col-md-4">
                    { masterButton }
                </div> */}
                <div className="col-md-3">
                    <div className="text-right">
                        <button type="button" className="btn btn-primary" onClick={ this.next }>próxima palabra</button>
                    </div>
                </div>
                <div className="col-md-12"><hr /></div>
                <div className="col-md-6">{quizButton}</div>
                <div className="col-md-6">{reset}</div>
                <div className="col-md-12">
                    <hr />
                   {instruction}
                   <br />
                </div>
           </div>
        </div>
        <div className="col-md-2"></div>
    </div>
        if(this.state.quizeMode)
                ComponentToShow = <QuizeComponent  recordId={ this.state.record } playedWords={this.state.playedWords } category={ this.props.match.params.id } quizeMode={this.updatequizeMode}/>;
        return(
        <div >
            {ComponentToShow}
        </div>   
        )
    }
}

export default Practice;