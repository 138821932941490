import React, { Component } from 'react';
import { BrowserRouter,Link,Route,  } from 'react-router-dom';
import Practice from './practice';
import Quiz from './quize';
import Catagorised from './catagorised';
import catagorisedNavbar from './catagorisedNavbar';
import AnotherComponent from './anotherComponent';
import Photos from './Photos';
 class UserHome extends Component{
     constructor(){
         super();
         this.state = {
            home:false
         }
         this.home = this.home.bind(this);
         
         
     }

     componentDidMount(){
        if(window.location.href.includes("page")){
            this.setState({home:true});
        }
     }
    logOut(){
        localStorage.removeItem('login');
        localStorage.removeItem('type');
        localStorage.removeItem('master');
        window.location.href = "https://gorilaingles.com";
    }
    home(){
        let home = !this.state.home;
        this.setState({home:home});
    }
    render(){
       let htmlcomponent =  <BrowserRouter>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link to="/" className="navbar-brand"  onClick={this.home}>Home</Link>
            <Link className="navbar-brand" to="/">Página de inicio</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link className="nav-link" to="/quiz/">Tomar cuestionario</Link>
                </li>
                </ul>
                <ul className="navbar-nav navbar-right">
                <li className="nav-item dropdown">
                    <Link className="dropdown-item" to="/" onClick={ this.logOut.bind(this) }>Cerrar sesión</Link>
                </li>
                </ul>
            </div>
            </nav>
          
            <Route  exact path="/" component={catagorisedNavbar}/>
            {/* <Route  exact path="/" component={Practice}/> */}
            <Route  exact path="/quiz/" component={Quiz}/>
            <Route  exact path="/quizbycategory/:id" component={Practice}/>
            <Route exact path="/wordwithpic" component={Photos} />
        </BrowserRouter>
        if(this.state.home)
        htmlcomponent = <AnotherComponent home={this.home} />;

        return(
        
            
        <div className="container-fluid">
           
            {htmlcomponent}       
        </div>   
        )
    }
}

export default UserHome;