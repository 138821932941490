import React, { Component } from 'react';
import { BrowserRouter,Redirect,Route } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import Word from './Word';
import UserList from './UserList';
import Emailverify from './Emailverify';
import Wordwithpic  from './Wordwithpic';
export default class Home extends Component {
    constructor(){
        super();
        this.state = {
            reload:true
        }
    }
    
    render() {
        
        return (
            <div className="wrapper">
                <BrowserRouter>
                    <Header />
                    <Sidebar />
                    <div className="content-wrapper">
                        <Route  exact path="/" component={Dashboard}/>
                        <Route  exact path="/word/:id" component={Word}/>
                        <Route  exact path="/users/" component={UserList}/>
                        <Route  exact path="/wordwithpic/" component={Wordwithpic}/>
                    </div>
                    <Footer />
                </BrowserRouter>
            </div>
        );
    }
}


