import React from 'react';

const Dashboard = () => {

        return(
            <div>
                <section className="content-header">
                    <h1>
                        Dashboard
                        <small>Control panel</small>
                    </h1>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-lg-12">
                           
                        </div>
                    </div>
                </section>
            </div>
        )

}


export default Dashboard;