import React from 'react';
import { Link } from 'react-router-dom';
const Page3 = () => {

    return(
        
        <div>
            <h2>El programa de aprendizaje de inglés más fácil para latinos</h2>
            <p className="oneLineText-2">
            Gorila ingles centra su programa en trabajar las 1000 palabras más importantes y las frases más utilizadas en el día a día de las conversaciones. Buscando con esto, garantizar que optimices tu tiempo y aprendizaje en frases y palabras útiles y que no pierdan vigencia en su uso. Cabe resaltar que a pesar de lo que se ha enseñado tradicionalmente, la realidad es que solo necesitas saber aproximadamente 1000 palabras en tu vocabulario de inglés y como mínimo30 frases, lo cual te permitirá tener un 70% de fluidez en el idioma inglés. De acuerdo con lo anterior, en gorila inglés, hemos creado un programa, pensando en los estudiantes, taxistas, médicos, meseras y cualquier persona que necesite aprender cómo desarrollar habilidades de comunicación en inglés de forma rápida, fácil y gratuita.
            </p>
            <br />
            <Link className="btn  btn-lg" to="/page2">Go Back</Link> <Link className="btn  btn-lg" to="/page4">Mas Información</Link>
        </div>
    
    )
}

export default Page3;