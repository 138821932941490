import React, { Component } from 'react'
import { BrowserRouter,Link, Route } from 'react-router-dom';
import topBanner from './images/banner.png';
import masterBanner from './images/masterBanner.png';
import page1 from './pages/page1';
import page2 from './pages/page2';
import page3 from './pages/page3';
import page4 from './pages/page4';
import page5 from './pages/page5';
import ContactUs from './pages/ContactUs';
import Home from './Home';
import UserHome from './users/Home';
import axios from 'axios';
export class Index extends Component {
    constructor(){
        super();
        this.state = {
            mobMenu:'',
            uname :'',
            password:'',
            message:'',
            messageClass:'',
            fpemail:'',
            forgetPasswordMessage:null
        }
        this.toggleMobMenu = this.toggleMobMenu.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.forgetPassword = this.forgetPassword.bind(this);
        if(window.location.href.includes("localhost"))
            localStorage.setItem('base_url','http://localhost/wordapis/api/');
        else if(window.location.href.includes("www"))
            localStorage.setItem('base_url','https://www.gorilaingles.com/wordapis/api/');
        else
            localStorage.setItem('base_url','https://gorilaingles.com/wordapis/api/');
    }

    handleInputChange (e)  {
        console.log(e.target.value);
        this.setState({ [ e.target.id ] : e.target.value });
    }

    onSubmit (e) {
        e.preventDefault();
        if(this.state.uname == '' || this.state.password == '' ){
            this.setState({message:'all fields are required',messageClass:'alert alert-danger'});
        }
        else{
            axios.post(localStorage.getItem('base_url') + 'login', this.state).then(res => {
                console.log(res.data);
                if(res.data){
                    localStorage.setItem('login', JSON.stringify(res.data));
                    localStorage.setItem('type', res.data.type);
                    localStorage.setItem('master', res.data.master);
                    localStorage.setItem('user_id', res.data.id);
                    localStorage.setItem('username', res.data.uname);
                    window.location.reload(true);
                }
                else if(res.data == "0"){
                    this.setState({ message:'username or password is Incorrect',messageClass:'alert alert-danger' });
                }
                
            }).catch(error => {
                this.setState({ message:error,messageClass:'alert alert-danger' });
            });
        }

        setTimeout(
            function() {
                this.setState({ message:'',messageClass:'' });
            }
            .bind(this),
            1000
        );
    }

    toggleMobMenu(){
        let mobMenu = !this.state.mobMenu
        this.setState({mobMenu:mobMenu});
    }

    forgetPassword(){
        axios.post(localStorage.getItem('base_url') + 'forgetpassword', {email:this.state.fpemail}).then(res => {
            this.setState({forgetPasswordMessage:res.data})
        }).catch(error => {
            this.setState({forgetPasswordMessage:error})
        });
    }

    render() {
        let mobMenu =
        <ul className="mobile list-group">
        <li className="list-group-item"><Link to="/" className="navbar-brand">Home</Link></li>
        <li className="list-group-item"><Link to="/contact-us" className="navbar-brand">Contact Us</Link></li>
        <li className="list-group-item"><Link to="/" className="navbar-brand" onClick={this.props.register}>Registro</Link></li>
        </ul>
        
        if(this.state.mobMenu == false)
                mobMenu = ''
        let htmlComponent = <BrowserRouter><div className="container-fluid mainDiv">
            
            <div className="container">
            <img src={"."+masterBanner}  className="topBannerImage"/>
            <div className="row">
                <div className="col-md-12">
                <nav className="navbar navbar-expand-lg navBar desktop">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li  className="nav-item aCursor"><Link to="/" className="navbar-brand">Pagina de inicio</Link></li>
                    </ul>  
                        <ul className="navbar-nav rightNav">
                            <li className="nav-item aCursor"><a className="navbar-brand"  data-toggle="modal" data-target="#login">Acceso a Estudiantes</a></li>
                            <li className="nav-item aCursor"><Link to="/" className="navbar-brand" onClick={this.props.register}>Registro</Link></li>
                            <li className="nav-item aCursor"><Link to="/contact-us" className="navbar-brand">Soporte</Link></li>
                        </ul>
                    </div>
                    </nav>
                 <div className="mobile">

                    <Link to="/" className="mobile btn btn-primary btn-block">Home</Link>
                    <Link to="/contact-us" className="mobile btn btn-primary btn-block">Contact Us</Link>
                    <Link to="/" className="mobile btn btn-primary btn-block" onClick={this.props.register}>Registro</Link>
                    <button className="mobile btn btn-primary btn-block" data-toggle="modal" data-target="#login">Acceso a Estudiantes</button>
                 </div>
                </div>
                
            </div>

            <div className="row content_area">
                <div className="col-md-12">
                    
                        <Route  exact path="/" component={page1}/>
                        <Route  exact path="/page2" component={page2}/>
                        <Route  exact path="/page3" component={page3}/>
                        <Route  exact path="/page4" component={page4}/>
                        <Route  exact path="/page5" component={page5}/>
                        <Route  exact path="/contact-us" component={ContactUs}/>
                    
                </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="login"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Formulario de ingreso</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form method="post" onSubmit={this.onSubmit}>
                    <div className="modal-body">
                    
                    <div className="form-group">
                        <label>Nombre de Usuario</label>
                        <input type="text" onChange={this.handleInputChange} id="uname" className="form-control" placeholder="Ingresa tu Nombre de Usuario"/>
                    </div>
                    <div className="form-group">
                        <label>Contraseña</label>
                        <input type="password" onChange={this.handleInputChange} id="password" className="form-control" placeholder="Ingresa tu Contraseña"/>
                    </div>
                    <hr />
                    <p  className={this.state.messageClass} > {this.state.message} </p>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                        olvide contraseña o nombre de usuario
                    </button>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        <button type="submit" className="btn btn-primary" >Ingresar</button>
                       
                    </div>
                </form>                
                </div>
            </div>
                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Password Reset</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                       <input type='email' id='fpemail' className="form-control" placeholder="Enter Your Rmail" onChange={this.handleInputChange}/>
                       <p className="text text-info"> { this.state.forgetPasswordMessage } </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.forgetPassword}>Save changes</button>
                        
                    </div>
                    </div>
                </div>
                </div>
        </div>
        </BrowserRouter>
        if(localStorage.getItem('login')){
            //console.log(localStorage.getItem('type'));
           if(localStorage.getItem('type') == 1)
                htmlComponent = <Home />;
           else
            htmlComponent = <UserHome />;
        }
        return (
            <div>
                {htmlComponent}
            </div>
        )
    }
}

export default Index
