import React from 'react';
import { Link } from 'react-router-dom';

const Page4 = () => {

    return(
        
        <div>
            <h3>Programas gratuitos de aprendizaje de inglés para latinos</h3>
           <p className="oneLineText-2">
           Podría llevar muchos años, incluso una vida, aprender todas las complicadas reglas de la gramática inglesa y créelo aprender la complicada gramática del inglés ni siquiera es necesario en la mayoría de las situaciones. La razón de esto es que el hablante nativo de ingléscon el que se comunicará en su mayoría utiliza el contexto, el conocimiento y la experiencia para comprender lo que está diciendo y una gramática perfecta no es absolutamente necesaria en la mayoría de las situaciones al tener una conversación en inglés. Además, las palabras en inglés se pronuncian de manera diferente, lo que hace que el aprendizaje de un libro o un sitio web tradicional sea prácticamente imposible. Esta es la razón principal por la que la mayoría de las personas no aprenden inglés.
           </p>
           <br />
           <Link className="btn  btn-lg" to="/page3">Go Back</Link> <Link className="btn  btn-lg" to="/page5">Mas Información</Link>
        </div>
    )
}

export default Page4;