import React, { Component } from 'react'
import axios from 'axios';
export class QuizeComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            words:[],
            quize:[],
            questionsAnswers: [],
            correctAnswers:0,
            incorrectAnswers:0,
            answersStatus: [],
            quizeSubmitted:false
        }
    }

    componentDidMount(){
        
        console.log(this.props.playedWords);

        // axios.get(localStorage.getItem('base_url')+'getquizerecords/'+this.props.recordId+'/'+this.props.category).then(res => {
        //     console.log(res.data);
        //     this.setState({ words:res.data });
        // })

        const questionsAnswers = [];
        
        for(let i = 0; i < this.props.playedWords.length; i++) {
            questionsAnswers.push([false, false, false, false])
        }

        this.setState({ words:this.props.playedWords, questionsAnswers })
        this.inputHandleChange = this.inputHandleChange.bind(this);
        this.submitQuize = this.submitQuize.bind(this);

    }


    labelClass(questionId, answerIndex,word,correctWord) {
        const answerStatus = this.state.answersStatus.find(v => v.index == questionId)
        if( answerStatus && answerStatus.status == 1 && answerStatus.answerIndex == answerIndex )
            return "option-green";
        else if( answerStatus && answerStatus.status == 0 && answerStatus.answerIndex == answerIndex )
            return "option-red";        
        return "";
    }

    labelClassForCorrectAnswer(correctWord,word){
        if(this.state.quizeSubmitted){
            if(correctWord == word){
                return "option-orange";
            }
        }   
    }
    inputHandleChange(e, k, k2) {
        
        const quizeItem = this.state.quize.find((v) => v.id == e.target.id )
        const quizeIndex = this.state.quize.indexOf(quizeItem);
        if(quizeIndex !== -1)
            this.state.quize.splice(quizeIndex,1);
        
        this.state.quize.push({ id:e.target.id , word:e.target.value });

        this.state.questionsAnswers[k] = [false, false, false, false];
        this.state.questionsAnswers[k][k2] = e.target.checked
        this.setState({ questionsAnswers: this.state.questionsAnswers });
    }


    submitQuize(){
        
        axios.post(localStorage.getItem('base_url')+'submitquiz',{quizeData : this.state.quize,user:localStorage.getItem('user_id') }).then(res => {
            
            const answersStatus = [];
            for(let k in res.data[2]) {
                answersStatus.push({ index: res.data[2][k].word_id,answer_value:this.state.quize[k].word,status: res.data[2][k].correct, answerIndex: this.state.questionsAnswers[k].indexOf(true) })
            }
            this.setState({
                answersStatus,
                correctAnswers:res.data[0],
                incorrectAnswers:res.data[1]
            })
        })
        this.setState({ quizeSubmitted:true })
        
    }
    playAgain(){
        this.setState({ words:[] })
        this.props.quizeMode();
    }
    render() {
        let option1 = "";
        let option2 = "";
        let option3 = "";
        let option4 = "";

        let button = <button onClick={ this.submitQuize } className="btn btn-primary">Submit Quize</button>
        if(this.state.quizeSubmitted)
            button = <button onClick={ this.playAgain.bind(this) } className="btn btn-primary">Play Again</button>
        return (
            <div>
               {
                   this.state.words.map( (res,key) =>{

                       return(
                        <div key={key}>
                                <h3> Quation : { res.spanish } </h3>
                                <hr />
                                <input onChange={ (e) => this.inputHandleChange(e, key, 0) } id={"_"+res.id} name={'ans'+key } checked={this.state.questionsAnswers[key][0]} type="radio" value={ res.option1 } /> <label className={ this.labelClass.bind(this)(res.id, 0,res.option1,res.english)+' '+this.labelClassForCorrectAnswer.bind(this)(res.option1,res.english) }>{ res.option1 }</label> <br />
                                <input onChange={ (e) => this.inputHandleChange(e, key, 1) } id={"_"+res.id} name={'ans'+key } checked={this.state.questionsAnswers[key][1]} type="radio" value={ res.option2 } /> <label className={ this.labelClass.bind(this)(res.id, 1,res.option2,res.english)+' '+this.labelClassForCorrectAnswer.bind(this)(res.option2,res.english) }>{ res.option2 }</label> <br />
                                <input onChange={ (e) => this.inputHandleChange(e, key, 2) } id={"_"+res.id} name={'ans'+key } checked={this.state.questionsAnswers[key][2]} type="radio" value={ res.option3 } /> <label className={ this.labelClass.bind(this)(res.id, 2,res.option3,res.english)+' '+this.labelClassForCorrectAnswer.bind(this)(res.option3,res.english) }>{ res.option3 }</label> <br />
                                <input onChange={ (e) => this.inputHandleChange(e, key, 3) } id={"_"+res.id} name={'ans'+key } checked={this.state.questionsAnswers[key][3]} type="radio" value={ res.option4 } /> <label className={ this.labelClass.bind(this)(res.id, 3,res.option4,res.english)+' '+this.labelClassForCorrectAnswer.bind(this)(res.option4,res.english) }>{ res.option4 }</label>
                         </div>
                       ) 
                   })
               }
               <hr />
               Correct Answers  { this.state.correctAnswers } <br />
               Incorrect Answers  : { this.state.incorrectAnswers }
               <hr />
                {button}
            </div>
        )
    }
}

export default QuizeComponent
