import React from 'react';
import { Link } from 'react-router-dom';
const Page2 = () => {

    return(
           
        <div>
            <h2>Sitio web de aprendizaje de inglés para latinos</h2>
            <p className="oneLineText-2">
                Gorila inglés es el programa de inglés más efectivo del mundo puesto que funciona de la misma manera que lo hace tu cerebro.
                Toda vez que al aprender un idioma extranjero,el cerebro procesa primero la palabra en español y luego la convierte en inglés. 
                La capacidad de traducir y la fluidez está determinada por la velocidad en la cual el cerebro traduce las palabras de un lado a otro y el volumen total de palabras y frases que conoce.
                En el corazónde gorila inglés está nuestro método de tarjeta flash, el cual permite que de forma natural elcerebro reciba y procese la información, logrando de esta manera que usted fácilmente entienda y hable el idioma inglés.
                Por ejemplo
            </p>
            <br />
            <Link className="btn  btn-lg" to="/">Go Back</Link>     <Link className="btn  btn-lg" to="/page3">Mas Información</Link>
            </div>
        
    )
}

export default Page2;