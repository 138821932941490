import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Sidebar extends Component {
    render() {
        return (
            <aside className="main-sidebar">
            <section className="sidebar">
              <div className="user-panel">
                <div className="pull-left info">
                  <p>{ localStorage.getItem('username') } </p>
                </div>
              </div>
              <ul className="sidebar-menu" data-widget="tree">
        
                {/* <li className="treeview">
                  <a href="#">
                    <i className="fa fa-table"></i> <span>Tables</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li><a href="pages/tables/simple.html"><i className="fa fa-circle-o"></i> Simple tables</a></li>
                    <li><a href="pages/tables/data.html"><i className="fa fa-circle-o"></i> Data tables</a></li>
                  </ul>
                </li> */}
                <li><Link to='/word/3'><i className="fa fa-book"></i> <span>Words</span></Link></li>
                <li><Link to='/word/4'><i className="fa fa-book"></i> <span>Sentence</span></Link></li>
                <li><Link to='/word/2'><i className="fa fa-book"></i> <span>Alphabets</span></Link></li>
                <li><Link to='/word/6'><i className="fa fa-book"></i> <span>Vowels</span></Link></li>
                <li><Link to='/word/1'><i className="fa fa-book"></i> <span>Numbers</span></Link></li>
                <li><Link to='/word/7'><i className="fa fa-book"></i> <span>Pictures</span></Link></li>
                <li><Link to='/users'><i className="fa fa-book"></i> <span>Users</span></Link></li>
        
              </ul>
            </section>
          </aside>
        
        )
    }
}
