import React,{Component} from 'react';
import axios from 'axios';
class UserList extends Component  {
    constructor(props){
        super(props);
        this.state = {
            users:[]
        }
        this.fetchUsers = this.fetchUsers.bind(this);
    }

    componentDidMount(){
        this.fetchUsers();
    }

    fetchUsers(){
        axios.get(localStorage.getItem('base_url')+'users').then(res => {
            console.log(res.data);
            this.setState({
                users:res.data
            })
        }).catch((e) => {

            console.log(e);
        })
    }
    render(){
        return(
            <div>
                <section className="content-header">
                    <h1>
                        Users List
                    </h1>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.users.map((user,i) =>{
                                        let status='';
                                        let type ='';
                                        if(user.status == 1)
                                            status='active'
                                        else
                                            status='inActive'

                                        if(user.type == 1)
                                            type = 'Admin';
                                        else
                                            type = 'User';
                                        return (
                                            <tr key={ i }>
                                                    <td>{ user.fname +" "+user.lname }</td>
                                                    <td>{ user.email }</td>
                                                    <td>{ status }</td>
                                                    <td>{ type }</td>
                                                </tr>
                                        )
                                    }
                                    )
                                }
                                
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default UserList;