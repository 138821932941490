import React, { Component } from 'react';
import axios from 'axios';

 class Quiz extends Component{
    constructor(){
        super();
        this.state = {
            text:"",
            record:0,
            master:19,
            data:[],
            hint:false,
            dataResponse:false
        }
        this.fetchWord = this.fetchWord.bind(this);
        this.updateMaster = this.updateMaster.bind(this)   
    }
    
    componentDidMount(){
        this.fetchWord(this.state.record);
    }
    
    fetchWord(limit){
        axios.get(localStorage.getItem('base_url')+'getMaster/'+localStorage.getItem('user_id')).then(res =>{
            this.setState({ master:parseInt(res.data) })
            console.log(res);
        })
        axios.post(localStorage.getItem('base_url')+'randomwordbylimit',{limit:limit,master:this.state.master}).then(res =>{
            if(res.data.length !== 0){
                this.setState({ 
                    data : res.data,
                    dataResponse:true
                 })
                
            }
            else{
                this.setState({ 
                    data :[],
                    dataResponse:false
                 })
            }
            console.log(this.state.dataResponse)
        })
    }

    updateMaster(){
        let master = this.state.master
       master +=10
       this.setState({ master:master,record:0 })
       axios.put(localStorage.getItem('base_url')+'updateMaster/'+localStorage.getItem('user_id'),{master:master}).then(res => {
           console.log('limit updated');
       })
       this.fetchWord(0);
    }
    repractice(){
        this.setState({ master:9,record:0 })
        this.fetchWord(0);
    }
    
    render(){
        let masterButton = '';
        let response =<div className="wordText">No More Words </div>;
        
        return(
            <div >
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                    Records 1 to { parseInt(this.state.master)+1 } And Current Count is = {this.state.record+1}
                        <ul>
                            
                            {
                                this.state.data.map((record,key)=>{
                                    if(this.state.dataResponse == true)
                                        response = <span >{ record.spanish }</span>
                                    
                                return(
                                        <li key={key} >{ response }</li>
                                    )
                                })
                            
                            }
                        </ul>
                    <div className="col-md-2"></div>
                    <div className="col-md-12"> <button onClick={this.updateMaster} className="btn btn-info btn-block">Master</button></div>
                </div>
            </div>    
            </div>
        )
    }
}

export default Quiz;