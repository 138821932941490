import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Home from '.././Home';
import UserHome from '.././users/Home';
import { BrowserRouter, Route ,Link,Redirect} from 'react-router-dom';
import Emailverify from '.././Emailverify';
import topBanner from '.././images/banner.png';
import masterBanner from '.././images/masterBanner.png';
import page1 from '.././pages/page1';
import page2 from '.././pages/page2';
import page3 from '.././pages/page3';
import page4 from '.././pages/page4';
import page5 from '.././pages/page5';
import ContactUs from '.././pages/ContactUs';
export default class AnotherComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobMenu:false
        }

        this.toggleMobMenu = this.toggleMobMenu.bind(this);
       
    }
    
    toggleMobMenu(){
        let mobMenu = !this.state.mobMenu
        this.setState({mobMenu:mobMenu});
    }
    render() {
        let mobMenu = <ul className="mobile list-group">
            <li className="list-group-item"><Link to="" className="navbar-brand">Home</Link></li>
            <li className="list-group-item"><Link to="" className="navbar-brand" onClick={this.props.home}>{ localStorage.getItem('username') }</Link></li>
            <li className="list-group-item"><Link to="/contact-us" className="navbar-brand">Soporte</Link></li>
        </ul>
        if(this.state.mobMenu == false)
        mobMenu = ''
        let htmlComponent = <BrowserRouter><div className="container-fluid mainDiv">
            
            <div className="container">
            <img src={"."+masterBanner}  className="topBannerImage"/>
            <div className="row">
                <div className="col-md-12">

                <nav className="navbar navbar-expand-lg navBar desktop">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li  className="nav-item aCursor"><Link to="/" className="navbar-brand">Pagina de inicio</Link></li>
                    </ul>  
                        <ul className="navbar-nav rightNav">
                            <li className="nav-item aCursor"><Link to="/" className="navbar-brand" onClick={this.props.home}>{ localStorage.getItem('username') }</Link></li>
                            <li className="nav-item aCursor"><Link to="/contact-us" className="navbar-brand">Soporte</Link></li>
                        </ul>
                    </div>
                    </nav>
                    <div className="mobile">

                        <Link to="/" className="mobile btn btn-primary btn-block">Home</Link>
                        <Link to="/contact-us" className="mobile btn btn-primary btn-block">Contact Us</Link>
                        <Link to="/" className="mobile btn btn-primary btn-block" onClick={this.props.register}>Registro</Link>
                        <button className="mobile btn btn-primary btn-block" data-toggle="modal" data-target="#login">Acceso a Estudiantes</button>
                    </div>
                </div>
            </div>

            <div className="row content_area">
                <div className="col-md-12">
                    
                        <Route  exact path="/" component={page1}/>
                        <Route  exact path="/page2" component={page2}/>
                        <Route  exact path="/page3" component={page3}/>
                        <Route  exact path="/page4" component={page4}/>
                        <Route  exact path="/page5" component={page5}/>
                        <Route  exact path="/contact-us" component={ContactUs}/>
                    
                </div>
            </div>
            </div>
        </div>
        </BrowserRouter>
        return (
            <div>
                <Route exact path='/accesscode/:code' component={Emailverify}/>
                { htmlComponent }
            </div>
        );
    }
}


