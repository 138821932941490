import React, { Component } from 'react'
import axios from 'axios';

export default class ContactUs extends Component {
    constructor(){
       super();
        this.state = {
            fname:'',
            lname:'',
            email:'',
            comment:'',
            message:'',
            messageClass:''
       } 
       this.contactUs = this.contactUs.bind(this);
       this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange (e)  {
        this.setState({ [ e.target.id ] : e.target.value });
    }
    contactUs(){
        if(this.state.email == "" || this.state.fname == "" || this.state.lname == "" || this.state.comment == "")
         this.setState({message:'All Fields are Required',messageClass:'alert alert-danger'});
        else
        {
            axios.post(localStorage.getItem('base_url')+'contactus',this.state).then(res => {
                console.log(res.data)
                this.setState({message:'Gracias. Hemos recibido tu email. Nos pondremos en contacto con usted lo antes posible.',messageClass:'alert alert-success'});
            })
        }
       
    }
    render() {
        return (
            <div>
                
                {/* <p>Telefono : +57 (301) 627-5590 </p>
                <p>Correo Electronico : <a target="_blank" href = "mailto: apoyo@gorilainglish.com">apoyo@gorilainglish.com</a></p> */}
                <div className="row">
                    <div className="col-md-12">
                        <h3>Soporte/Contáctanos</h3>
                        <hr />
                        <p className={this.state.messageClass}>{ this.state.message }</p>
                        <div className="form-group">
                            <label>Nombre</label>
                            <input type="text" id='fname' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresar  nombre" />
                        </div>
                        <div className="form-group">
                            <label>Apellido</label>
                            <input type="text" id='lname' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresar  apellido" />
                        </div>
                        <div className="form-group">
                            <label>Correo Electrónico</label>
                            <input type="email" id='email' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresa tu correo electrónico" />
                        </div>
                        <div className="form-group">
                            <label>Comentarios</label>
                            <textarea id='comment' onChange={ this.handleInputChange } className="form-control"  />
                        </div>
                    </div>
                </div>
                <button className="btn btn-lg" onClick = { this.contactUs }>Ingresar / Enviar</button>
            </div>
        )
    }
}
