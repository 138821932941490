import React from 'react';
import { Link } from 'react-router-dom';

const Page5 = () => {

    return(
        
        <div>
            <h3>La mejor manera de aprender inglés rápido y fácil</h3>
           <p className="oneLineText-2">
            Aprender una nueva palabra en inglés es una completa pérdida de tiempo a menos que puedas pronunciarla. En gorila inglésusamos el método más innovador del mundo que permite prepararte para que tengas una correcta pronunciación. ¿Sólo hay 44 sonidos diferentes en inglés y adivina qué? Lamayoría ya los conoces porque son idénticos al español. Por todo lo anterior, hemos desarrollado un método revolucionario que llamamos "Sugerencias" para enseñarte a pronunciar ¡USANDO ESPAÑOL! Así es, aprenderás a pronunciar palabras en inglés usando el idioma español. No hay libros ni escuelas costosas, sólo necesitas abrir tu mente y tener la mejor disposición para aprender de la manera más fácil y práctica del mercado
            </p>
            <br />
            <Link className="btn  btn-lg" to="/page4">Go Back</Link>     <Link className="btn  btn-lg" to="/">Mas Información</Link>
        </div>
    )
}

export default Page5;