import React, { Component } from 'react';
import axios from 'axios';
export default class Forgotpassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            email :'',
            password:'',
            message:'',
            messageClass:'',
            code:'',
            password:'',
            confirmPassword:'',
            response:0
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCode = this.onSubmitCode.bind(this);
        this.updatePassword = this.updatePassword.bind(this);

        
    }
    handleInputChange (e)  {
        this.setState({ [ e.target.id ] : e.target.value });
    }
    onSubmit () {
        if (this.state.email != "") {
            axios.post(localStorage.getItem('base_url') + 'confirmemail', this.state).then(res => {
                if(res.data == 1){
                    this.setState({ response:1,message:"We Have Sent You a code via Email please Enter That Code in the input Below",messageClass:'text text-info' })
                }
                else{
                    this.setState({ response:0,message:"No account with this Email",messageClass:'text text-danger' })
                }
            }).catch(error => {
                this.setState({ message:error,messageClass:'text text-danger' });
            });
        }
        else {
            this.setState({ message:'all fields are required',messageClass:'text text-danger' });
        }
    }
    onSubmitCode(){
        if (this.state.email != "") {
            axios.post(localStorage.getItem('base_url') + 'confirmcode', this.state).then(res => {
                if(res.data == 1){
                    this.setState({ response:2,message:"",messageClass:'' })
                }
                else{
                    this.setState({ message:"Invalid Code",messageClass:'text text-danger' })
                }
            }).catch(error => {
                this.setState({ message:error,messageClass:'text text-danger' });
            });
        }
        else {
            this.setState({ message:'all fields are required',messageClass:'text text-danger' });
        }
    }

    updatePassword(){
        if(this.state.password  != "" && this.state.confirmPassword != ""){
        if (this.state.password  == this.state.confirmPassword) {
            axios.post(localStorage.getItem('base_url') + 'updatepassword', this.state).then(res => {
                if(res.data == 1){
                    this.setState({ response:3,message:"Password Has Been Updated Successfully",messageClass:'text text-success' })
                }
                else{
                    this.setState({ message:"there is an error please try later",messageClass:'text text-danger' })
                }
            }).catch(error => {
                this.setState({ message:error,messageClass:'text text-danger' });
            });
        }
        else {
            this.setState({ message:'Password And Confirm PAssword Must Match',messageClass:'text text-danger' });
        }
        }
        else {
            this.setState({ message:'all fields are required',messageClass:'text text-danger' });
        }
    }
    render() {
        let htmlComponent = <div className="wrapper-auth">
                            <div className="container-fluid login">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                    
                                        <div className="form-group">
                                            <label>Please Enter Your Email</label>
                                            <input type="email" value={this.state.email} id='email' onChange={ this.handleInputChange } className="form-control" placeholder="Enter Your Email" />
                                        </div>
                                        <div className={this.state.messageClass}> {this.state.message} </div>
                                        <button className="btn btn-info btn-block" onClick={ this.onSubmit }>Submit</button>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                        </div>;
                if(this.state.response == 1){
                     htmlComponent = <div className="wrapper-auth">
                            <div className="container-fluid login">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Please Enter The Code</label>
                                            <input type="text" id='code' value={this.state.code} onChange={ this.handleInputChange } className="form-control" placeholder="Enter the Code" />
                                        </div>
                                        <div className={this.state.messageClass}> {this.state.message} </div>
                                        <button className="btn btn-info btn-block" onClick={ this.onSubmitCode }>Submit</button>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                        </div>;
                }
                if(this.state.response == 2){
                    htmlComponent = <div className="wrapper-auth">
                           <div className="container-fluid login">
                               <div className="row">
                                   <div className="col-md-4"></div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label>Please Enter New Password</label>
                                           <input type="password" id='password' value={this.state.password} onChange={ this.handleInputChange } className="form-control" placeholder="Enter the Password" />
                                       </div>
                                       <div className="form-group">
                                           <label>Confirm Password</label>
                                           <input type="password" id='confirmPassword' value={this.state.confirmPassword} onChange={ this.handleInputChange } className="form-control" placeholder="Confirm Password" />
                                       </div>
                                       <div className={this.state.messageClass}> {this.state.message} </div>
                                       <button className="btn btn-info btn-block" onClick={ this.updatePassword } >Submit</button>
                                   </div>
                                   <div className="col-md-4"></div>
                               </div>
                           </div>
                       </div>;
               }
               if(this.state.response == 3){
                htmlComponent = <div className="wrapper-auth">
                       <div className="container-fluid login">
                           <div className="row">
                               <div className="col-md-4"></div>
                               <div className="col-md-4">
                                   <h3 className="text text-success">Password Updated Successfully</h3>
                                   <button className="btn btn-info btn-block" onClick={ this.props.login } >Login Here</button>
                               </div>
                               <div className="col-md-4"></div>
                           </div>
                       </div>
                   </div>;
           }
                    
        return (
            <div>
               
                { htmlComponent }
            </div>
        );
    }
}


