import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            fname:'',
            lname:'',
            uname:'',
            age:0,
            gender:'',
            email :'',
            password:'',
            message:'',
            messageClass:'',
            country:'',
            confirm_password:''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // localStorage.setItem('base_url','http://localhost/word/public/api/');
        //localStorage.setItem('base_url','http://premierdairy.pk/word/public/api/');
    }
    handleInputChange (e)  {
        this.setState({ [ e.target.id ] : e.target.value });
    }
    onSubmit () {
        if (this.state.fname == "" || this.state.lname == "" || this.state.age == "" || this.state.gender == "" || this.state.country == "" || this.state.password == "") {
            
            this.setState({ message:'all fields are required',messageClass:'text text-danger' });
            //console.log('i am here');
        }
        else if(this.state.password != this.state.confirm_password){
            this.setState({ message:'Password and Confirm Password Field Must Be Same',messageClass:'text text-danger' });
        }
        else {
            axios.post(localStorage.getItem('base_url') + 'signup', this.state).then(res => {
              console.log(res.data[1]);
                if(res.data[0] == 1){
                this.setState({ message:'You Are Signed Up successfully  Please Check Your Email you will shortly receive  an  email Verification link  Thanks !',messageClass:'text text-success' })
                localStorage.setItem('login', JSON.stringify(res.data[1]));
                localStorage.setItem('type', res.data[1].type);
                localStorage.setItem('master', res.data[1].master);
                localStorage.setItem('user_id', res.data[1].id);
                localStorage.setItem('username', res.data[1].uname);
                window.location.reload(true);
              }
               else if(res.data[0]  == 0)
                this.setState({ message:'User Name or Email is Already Existed',messageClass:'text text-danger' })
            }).catch(error => {
                this.setState({ message:error , messageClass:'text text-danger' })
            });
        }
    }
    render() {
        return (
        <div className="wrapper-auth">
        <div className="container-fluid login">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                    <h3>Formulario de registro</h3>
                        <hr />
                        <div className="form-group">
                        <label>Nombre</label>
                            <input type="text" id='fname' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresa tu Nombre" />
                        </div>
                        <div className="form-group">
                        <label>Apellido</label>
                            <input type="text" id='lname' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresa tu Apellido" />
                        </div>
                        <div className="form-group">
                        <label>Crea tu nombre de Usuario</label>
                            <input type="text" id='uname' onChange={ this.handleInputChange } className="form-control" placeholder="Crea tu nombre de Usuario" />
                        </div>
                        <div className="form-group">
                        <label>Edad</label>
                            <input type="number" min='0' id='age' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresa tu Edad" />
                        </div>
                        <div className="form-group">
                            <label>Sexo</label>
                            <select id='gender' onChange={ this.handleInputChange } className="form-control">
                                <option value="">select</option>
                                <option value="1">Male</option>
                                <option value="0">Female</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Correo electrónico (  optional  ) </label>
                            <input type="email" id='email' onChange={ this.handleInputChange } className="form-control" placeholder="Ingresa Correo electrónico" />
                        </div>
                        <div className="form-group">
                            <label>Crear  Contraseña</label>
                            <input type="password"  id='password' onChange={ this.handleInputChange } className="form-control" placeholder="Crea tu  Contraseña"/>
                        </div>
                        <div className="form-group">
                            <label>Confirmar Contraseña</label>
                            <input type="password"  id='confirm_password' onChange={ this.handleInputChange } className="form-control" placeholder="Confirma tu Contraseña"/>
                        </div>
                        <div className="form-group">
                            <label>país</label>
                            <input type="text"  id='country' onChange={ this.handleInputChange } className="form-control" placeholder="país"/>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className={this.state.messageClass} > { this.state.message }</p>
                                <hr/>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group">
                                
                            <button type="submit" onClick={this.onSubmit} className="btn btn-success" >Regístrate</button> 
                            </div>
                            </div>
                            <div className="col-md-9">
                            <button className="btn btn-primary btn-block" onClick={ this.props.login }>Ya eres estudiante?  Iniciar sesión aquí.</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </div>
        </div>
        );
    }
}


