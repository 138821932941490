import React,{Component} from 'react'; 
import Login from './Login';
import Register from './Register';
import Forgotpassword from './Forgotpassword';
import Index from './Index';
export default class Authentication extends Component{
    constructor(){
        super();
        this.state = {
            formToShow:0
        }
        this.register = this.register.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.login = this.login.bind(this);
    }
    login(){
        this.setState( {'formToShow':0 } )
    }
    register(){
        this.setState( {'formToShow':1 } )
    }
    forgotPassword(){
        this.setState( {'formToShow':2 } )
    }
    render() {
        let Auth = <Login register={this.register} forgotPassword={this.forgotPassword}/>;
        
        if(this.state.formToShow == 1)
             Auth = <Register register={this.register} login={ this.login } />;
        else if( this.state.formToShow == 2 )
            Auth =<Forgotpassword  login={ this.login }/>;
        else if(this.state.formToShow == 0)
            Auth = <Index register={this.register} forgotPassword={this.forgotPassword}/>
        return (
            <div>
                { Auth }
            </div>
        );
    }

}