import React, { Component } from 'react'
import axios from 'axios';


export class Photos extends Component {
    constructor(){
        super();
        this.state = {
            wordList:[]
        }
    }

    componentDidMount(){
        axios.get(localStorage.getItem('base_url')+'wordswithpiclist').then(res => {
            console.log(res.data[1],"total Words");
            this.setState({
                wordList:res.data[0]
            })
        }).catch((e) => {

            console.log(e);
        })
    }
    render() {
        console.log('words',this.state.wordList);
        return (
        <div>
            <div className="row">
                <div className="col-md-2"></div>
                    <div className="col-md-8">
                    <div className="box" >
                    {
                        this.state.wordList.map((word,i)=>{
                            console.log(word.word);
                            return (
                                <div key={i} className="boxChild">
                                   {word.word}
                                   <br />
                                   <img src={"https://gorilaingles.com/word/public/assets/"+word.pic} />
                                </div>
                            )
                        })
                    }  
                        
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            </div>
        )
    }
}

export default Photos
