import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Login from './Login';
import Home from './Home';
import { BrowserRouter, Route } from 'react-router-dom';
import Authentication from './Authentication';

export default class MainComponent extends Component {
    render() {
        return (
        <div>
            <BrowserRouter>
                <Route  path='/' component={Authentication}/>
                
            </BrowserRouter>
        </div>
        );
    }
}

if (document.getElementById('maincomponent')) {
    ReactDOM.render(<MainComponent />, document.getElementById('maincomponent'));
}

