import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {

    logOut(){
        localStorage.removeItem('login');
        localStorage.removeItem('type');
        localStorage.removeItem('master');
        window.location.href = "https://gorilaingles.com";
    }
    render() {
        return (
                <header className="main-header">
                    <Link to='/'  className="logo">
                    <span className="logo-mini"><b>A</b>LT</span>
                    <span className="logo-lg"><b>Admin</b>LTE</span>
                    </Link>
                    <nav className="navbar navbar-static-top">
                    <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <div className="pull-right">
                        <Link to='/' onClick={ this.logOut.bind(this) } className="btn btn-info btn-flat">Sign out</Link>
                    </div>
                    </nav>
                </header>
        )
    }
}
