import React, { Component } from 'react';
import { BrowserRouter,Link,Route } from 'react-router-dom';

 class catagorisedNavbar extends Component{
    render(){
        
        return(
        <div className="container">
                    <center>
                        <div className="div728by90">
                            {/* <p className="advertisement">advertisement</p> */}
                        </div>
                        <br />
                    </center>
                    <div className="row">
                    <div className="div300by250">
                        {/* <p className="advertisement">advertisement</p> */}
                    </div>
                    <div className="restWidth">
                    <center>
                    <br />
                        <h3><Link to="/quizbycategory/3" className="Links">1000 palabras inglesas más importantes</Link></h3>
                        <br />
                         <h3><Link to="/quizbycategory/4" className="Links">Frases inglesas más importantes</Link></h3>
                        <br />
                         <h3><Link to="/quizbycategory/2" className="Links">Alfabeto inglés</Link></h3>
                        <br />
                         <h3><Link to="/quizbycategory/6" className="Links">Vocales inglesas</Link></h3>
                        <br />
                        <h3><Link to="/quizbycategory/1" className="Links">Números ingleses</Link></h3>
                        <br />
                        <h3><Link to="/quizbycategory/7" className="Links">Pictures</Link></h3>
                    </center>
                    </div>
                    <div className="div160by600">
                        {/* <p className="advertisement">advertisement</p> */}
                    </div>
                </div>
            </div>   
        )
    }
}

export default catagorisedNavbar;