import React from 'react';
import { Link } from 'react-router-dom';
const Page1 = () => {

    return(
        <div>
            <h3>Esta es la razón por lo cual nuestro programa es diferente:</h3>
                <p className="oneLineText-2">
                Gorila inglés un novedoso programa de estudios en el cual se aprende el idioma inglés de una forma más económica, fácil y efectiva para todos los latinos en el planeta. Con nuestro programa revolucionario, tendrás un 70% de fluidez en inglés en menos de un año, te lo garantizamos! Otros programas y escuelas de inglés desperdician su tiempo y dinero enseñando una gramática del idioma inglés muy compleja y anticuada, además de miles de palabras innecesarias y poco usadas en conversaciones hoy en día. Las escuelas de inglés y los programas de aprendizaje fallan el 98% de los casos,causando frustración, pérdida de tiempo y desperdicio de dinero
            </p>
            <br />
            <Link className="btn  btn-lg" to="/page2">Mas Información</Link>
        </div> 
    )
}

export default Page1;