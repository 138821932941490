import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Home from './Home';
import UserHome from './users/Home';
import { BrowserRouter, Route } from 'react-router-dom';
import Emailverify from './Emailverify';
export default class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email :'',
            password:'',
            message:'',
            messageClass:''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        if(window.location.href.includes("localhost"))
            localStorage.setItem('base_url','http://localhost/word/api/');
        else if(window.location.href.includes("www"))
            localStorage.setItem('base_url','https://www.gorilaingles.com/api/');
        else
            localStorage.setItem('base_url','https://gorilaingles.com/api/');
        
    }
    handleInputChange (e)  {
        this.setState({ [ e.target.id ] : e.target.value });
    }
    onSubmit () {
        if (this.state.email != "" || this.state.password != "") {
            axios.post(localStorage.getItem('base_url') + 'login', this.state).then(res => {
                console.log(res.data);
                if(res.data){
                    localStorage.setItem('login', JSON.stringify(res.data));
                    localStorage.setItem('type', res.data.type);
                    localStorage.setItem('master', res.data.master);
                    localStorage.setItem('user_id', res.data.id);
                    window.location.reload(true);
                }
                else if(!res.data){
                    this.setState({ message:'Email/username or Password is Incorrect',messageClass:'text text-danger' });
                }
                
            }).catch(error => {
                this.setState({ message:error,messageClass:'text text-danger' });
            });
        }
        else {
            this.setState({ message:'all fields are required',messageClass:'text text-danger' });
        }
    }
    toggleMobMenu(){
        let mobMenu = !this.state.mobMenu
        this.setState({mobMenu:mobMenu});
    }
    render() {
        let htmlComponent = <div className="wrapper-auth">
                            <div className="container-fluid login">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <h3>Login Form</h3>
                                        <hr />
                                        <div className="form-group">
                                            <label>Email  / Username</label>
                                            <input type="email" id='email' onChange={ this.handleInputChange } className="form-control" placeholder="Enter Your Email" />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password"  id='password' onChange={ this.handleInputChange } className="form-control" placeholder="Enter Your Password"/>
                                        </div>
                                        <div className="row">
                                        <div className="col-md-12">
                                            <p className={this.state.messageClass} > { this.state.message }</p>
                                            <hr/>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <button type="submit" onClick={this.onSubmit} className="btn btn-success" >Login</button> 
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <button className="btn btn-info btn-block" onClick={this.props.register}>New User ? Register Here</button>
                                        </div>
                                        </div>
                                        <button className="btn btn-info btn-block" onClick={ this.props.forgotPassword }>Forgot Password</button>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            </div>
                        </div>;
        if(localStorage.getItem('login')){
            //console.log(localStorage.getItem('type'));
           if(localStorage.getItem('type') == 1)
                htmlComponent = <Home />;
           else
            htmlComponent = <UserHome />;
        }
                
        return (
            <div>
                <Route exact path='/word/accesscode/:code' component={Emailverify}/>
                { htmlComponent }
            </div>
        );
    }
}


