import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
export default class Emailverify extends Component {
    constructor(props){
        super(props);
        this.state = {
            textToDisplay :''
        }
       
    }
    componentDidMount(){
        console.log(this.props.match.params.code); 
        axios.post(localStorage.getItem('base_url')+'updatestatus',{code:this.props.match.params.code}).then(res=>{
            if(res.data){
                this.setState({textToDisplay:"congratulations Your Email is  Varified Now You Can Log In"}).setTimeout(() => {
                    window.location.href ="https://www.google.com"; 
             }, 1000);
            }
            else{
                this.setState({textToDisplay:"Invalid Link"});
            } 
        })
    }
    render() {
        return (
        <div className="wrapper-auth">
            <div className="container-fluid login">
                <div className="row">
                    <div className="col-md-12">
                        <center>
                            <h3>{ this.state.textToDisplay }</h3>
                            <br /> 
                            <Link to='/word/public/'>Please Click Here</Link>
                        </center>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}


