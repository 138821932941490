import React,{Component} from 'react';
import { number } from 'prop-types';
import axios from 'axios';
class Wordwithpic extends Component  {
    constructor(props){
        super(props);
        this.state = {
            engInput:'',
            message:'',
            messageClass:'',
            recordId:'',
            wordList:[],
            currentPage:1,
            totalPages:0,
            initialCount:1,
            maxCount:10,
            category:1,
            search:'',
            fileInput:'',
            imgSrc:'',
        }
        this.handleChangeInputs = this.handleChangeInputs.bind(this);
        this.saveWords = this.saveWords.bind(this);
        this.fetchWords = this.fetchWords.bind(this);
        this.updateRecord = this.updateRecord.bind(this);
        this.currentPage = this.currentPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.showCategory = this.showCategory.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.fileChange = this.fileChange.bind(this);
    }

    componentDidMount(){
     this.fetchWords();
     
    }
    componentWillReceiveProps(nextProps) {
        this.showCategory(nextProps);
        this.setState({
            currentPage:1
        })
    }
    showCategory(props) {
        let queryParam = props.match.params.id;
        this.setState({category:queryParam});
        this.fetchWords(queryParam);
    }

    handleChangeInputs(e) {
        this.setState({ [e.target.id]:e.target.value })
    }

    handleChangeSearch(e){
        console.log(e.target.value);
        this.setState({ [e.target.id]:e.target.value })
        if(e.target.value != ""){
            axios.post(localStorage.getItem('base_url')+'words/searchwithpic',{category:this.props.match.params.id,search:e.target.value}).then(res => {
                console.log(res.data[1],"total Words");
                this.setState({
                    wordList:res.data[0],
                    totalPages:Math.round(parseInt(res.data[1])/10)
                })

                if( Math.round(parseInt(res.data[1])/10) < 10)
                    this.setState({ maxCount:Math.round(parseInt(res.data[1])/10) })
                else
                this.setState({ maxCount:10  })
            }).catch((e) => {

                console.log(e);
            })
        }else{
            this.fetchWords(this.props.match.params.id);
        }
    }
    fileChange(evt) {
        console.log(evt.target.files[0]);
        this.setState({ fileInput: evt.target.files[0] });
        this.setState({ imgSrc: URL.createObjectURL(evt.target.files[0]) })
        // console.log(this.state.file);
    }
    saveWords(e){

        e.preventDefault();
        console.log('working');
        if(this.state.engInput != "" && this.state.fileInput != "" ){
            const formData = new FormData();
            formData.append('engInput', this.state.engInput);
            formData.append('fileInput', this.state.fileInput);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(localStorage.getItem('base_url') + 'savewordwithpic', formData, config).then((response) => {
                this.setState({
                    message:"Word Saved Success Fully",
                    messageClass:"alert alert-success",
                    engInput:'',
                    spanInput:'',
                    hintInput:'',
                    fileInput:''
                })
                this.fetchRecordsByPage(this.state.currentPage);
            }).catch((e)=>{
                //console.log(e);
                this.setState({
                    message:"Error in Saving Word Try Later",
                    messageClass:"alert alert-danger"
                })
            });
        }
        else{
            this.setState({
                message:"All Fields Are Required",
                messageClass:"alert alert-danger"
            })
        }
        
    }

    fetchWords(){
        axios.get(localStorage.getItem('base_url')+'wordswithpiclist').then(res => {
            console.log(res.data[1],"total Words");
            this.setState({

                wordList:res.data[0],
                totalPages:Math.round(parseInt(res.data[1])/10)
            })

            if( Math.round(parseInt(res.data[1])/10) < 10)
                this.setState({ maxCount:Math.round(parseInt(res.data[1])/10) })
            else
            this.setState({ maxCount:10  })
        }).catch((e) => {

            console.log(e);
        })
    }
    deleteRecord(recordId){
        console.log(recordId);
        axios.delete(localStorage.getItem('base_url')+'word/wordwithpicdelete/'+recordId).then(response=>{
            var data =this.state.wordList;
            for(var i=0;i < data.length;i++){
                if(data[i].id == recordId){
                    data.splice(i,1);
                    this.setState({wordList:data});
                }
               
            }
            
       }); 
    }

    editRecord (recordId){
        this.setState({fileInput: ''});
        axios.get(localStorage.getItem('base_url')+'wordwithpic/edit/'+recordId).then(res => {
            console.log(res.data.file);
            this.setState({
                recordId:recordId,
                engInput:res.data.word,
                fileInput:res.data.pic,
            })
        })


        console.log(this.state.fileInput);
    }

    updateRecord(e){
        e.preventDefault();        
        console.log('working');
        if(this.state.engInput != "" && this.state.spanInput != "" ){
            const formData = new FormData();
            formData.append('engInput', this.state.engInput);
            formData.append('fileInput', this.state.fileInput);
            formData.append('recordId', this.state.recordId);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(localStorage.getItem('base_url')+'word/updatewordwithpic',formData, config).then(res=>{
                this.setState({
                    message:"Word Updated SuccessFully",
                    messageClass:"alert alert-success",
                    
                })
                this.fetchRecordsByPage(this.state.currentPage);
            })
        }
        else{
            this.setState({
                message:"All Fields Are Required",
                messageClass:"alert alert-danger"
            })
        }
    }

    unsetStates(){
        this.setState({
            engInput:'',
            spanInput:'',
            hintInput:''
        })
    }

    previousPage(){
        let page ;
        if(this.state.currentPage > 1){
            page = this.state.currentPage - 1;
            this.setState({ currentPage:page });
            this.increasePageCount(page);
        }
        
    }
    nextPage(){
        let page;
        if(this.state.currentPage < this.state.totalPages){
                page = this.state.currentPage + 1;
                this.setState({ currentPage:page });
                this.increasePageCount(page);
       }
        
       
    }
    currentPage(page){
        this.setState({currentPage:page});
        this.increasePageCount(page);
    }

    increasePageCount(incomingPage){
        console.log(incomingPage,this.state.totalPages);
        if(incomingPage == 10 || incomingPage > 10){
            if(incomingPage > this.state.currentPage){
                if(incomingPage < this.state.totalPages){
                    let initialCountInc = incomingPage - 8;
                    const maxCountInc = incomingPage+1;
                    this.setState({ initialCount:initialCountInc,
                        maxCount :maxCountInc,
                        
                    })
                }
                else{
                    const initialCountInc = this.state.totalPages - 9;
                    const maxCountInc = this.state.totalPages;
                    this.setState({ initialCount:initialCountInc,
                        maxCount :maxCountInc,
                        
                    })
                }
               
            }
            else if(incomingPage < this.state.currentPage){
                const initialCountDec = this.state.initialCount - 1;
                const maxCountDec = this.state.maxCount - 1;
                this.setState({ initialCount:initialCountDec,
                    maxCount :maxCountDec
                })
            }
        }
        else if( incomingPage < 10){
            if(this.state.totalPages < 10){
                const totalPages = this.state.totalPages;
                this.setState({ initialCount:1,
                    maxCount :totalPages
                })
            }else{
                this.setState({ initialCount:1,
                    maxCount :10
                })
            }
            
        }
        this.fetchRecordsByPage(incomingPage);
    }

    fetchRecordsByPage(page){
        axios.post(localStorage.getItem('base_url')+'fetchRecordsByPagewithpic',{page:page,category:this.state.category}).then(res => {
            this.setState({
                wordList:res.data[0],
                totalPages:Math.round(Math.round(parseInt(res.data[1])/10))
            })
        })
    }
    
    render(){
        let items = []
        for(var i=this.state.initialCount;i <= this.state.maxCount;i++){
            items.push(<li key={i}><button className="list-item" onClick={this.currentPage.bind(this,i)}>{i}</button></li>)
        }
        return(
            <div>
                <section className="content-header">
                    <h1>
                        Word List       
                    </h1>
                    <button className="btn btn-info create-word" data-toggle="modal" onClick={ this.unsetStates.bind(this) } data-target="#modal-default">Create Word</button>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-lg-12">
                            <hr />
                                <input id="search" placeholder="Enter words to search " value={  this.state.search } onChange={ this.handleChangeSearch } type="text" className="form-control" />
                            <hr />
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>English</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.wordList.map((word,i) =>{
                                        return (
                                            <tr key={ i }>
                                                    <td>{ word.word }</td>
                                                    <td> <button className="btn btn-sm btn-danger" onClick={ this.deleteRecord.bind(this,word.id) }>Delete</button> | <button data-toggle="modal" data-target="#modal-default-update" className="btn btn-sm btn-warning" onClick={this.editRecord.bind(this,word.id)}>Edit</button></td>
                                                </tr>
                                        )
                                    }
                                    )
                                }
                                
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>English</th>
                                    <th>Actions</th>
                                </tr>
                                </tfoot>
                            </table>
                            <ul className="pagination">
                                <li><button className="list-item" onClick={ this.currentPage.bind(this,1) }>First Page</button></li>
                                <li><button className="list-item" onClick={this.previousPage}>Previous</button></li>
                                    { items }
                                <li><button className="list-item" onClick={this.nextPage}>Next</button></li>
                                <li><button className="list-item" onClick={this.currentPage.bind(this,this.state.totalPages)}>Last Page</button></li>
                                <li className="list-item">current Page : { this.state.currentPage }</li>
                            </ul>
                                 
                        </div>
                    </div>
                </section>


                <div className="modal fade" id="modal-default">
                    <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-heading">Create Words</h3>
                        <button type="button" className="close create-word" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                        
        
                    </div>
                    <form onSubmit={this.saveWords}>
                    <div className="modal-body">
                        <div className={this.state.messageClass}> { this.state.message } </div>
                        
                        <div className="form-group">
                            <label>English</label>
                            <input placeholder="Enter English Words" value={this.state.engInput} onChange={ this.handleChangeInputs} type="text" id="engInput" className="form-control" />
                        </div>            
                        <div className="form-group">
                            <label>File</label>
                            <input type="file" onChange={this.fileChange}  accept=".jpg,.png,.jpeg"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default pull-left" data-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary">Save Words</button>
                    </div>
                    </form>
                    </div>
                    
                </div>
                </div>
                <div className="modal fade" id="modal-default-update">
                    <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-heading">Update Words</h3>
                        <button type="button" className="close create-word" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                        
        
                    </div>
                    <form onSubmit={this.updateRecord}>

                    <div className="modal-body">
                        <div className={this.state.messageClass}> { this.state.message } </div>
                        
                        <div className="form-group">
                            <label>English</label>
                            <input placeholder="Enter English Words" value={this.state.engInput} onChange={ this.handleChangeInputs} type="text" id="engInput" className="form-control" />
                        </div>
                        <div className="form-group">
                            old file : { this.state.fileInput ? <img  src={'https://gorilaingles.com/word/public/assets/'+this.state.fileInput } /> : '' }
                            <br />
                            <label>File</label>
                            <input type="file" onChange={this.fileChange} accept="audio/mp3"/>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default pull-left" data-dismiss="modal">Close</button>
                        <button type="submit"   className="btn btn-primary">Update Word</button>
                    </div>
                    </form>
                    </div>
                    
                </div>
                </div>
            </div>
        )
    }
}


export default Wordwithpic;